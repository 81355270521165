.app-sidebar {
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: $main-color;
}

.app-siderbar-switcher {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  color: $text-white;
  justify-content: space-between;
}

.app-siderbar-switcher .btn {
}

.app-sidebar-brand {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 90px;
  padding: 1rem 0 1rem 2rem;
  color: $text-white;
}

.app-sidebar-brand h2 {
  font-size: 30px;
}

.app-sidebar-brand span {
  display: inline-block;
  padding-right: 1rem;
}

.app-sidebar-menu {
  flex: 1;
  margin-top: 1rem;
}

.app-sidebar-menu ul {
  padding: 0 !important;
}

.app-sidebar-menu li {
  width: 100%;
  margin-bottom: 0.3rem;
  padding-left: 1rem;
}

.app-sidebar-menu a {
  display: block;
  color: $text-white;
  font-size: 1.1rem;
  text-decoration: none;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.app-sidebar-menu a.active,
.app-sidebar-menu a:hover {
  background: $text-white;
  color: $main-color;
  border-radius: 30px 0 0 30px;
}

.app-sidebar-menu a span:first-child {
  font-size: 1.5rem;
  padding-right: 1rem;
}
