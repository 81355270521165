.product-details-container {
  display: flex;
  align-items: start;
  max-width: 1200px;
  margin: 20px auto;
  border: 1px solid #ccc;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-image-slider {
  flex: 1;
  margin-right: 20px;
}

.product-image-slider img {
  width: 100%;
  height: auto;
}

.product-info {
  flex: 1;
}

.product-info h1 {
  font-size: 28px;
  margin: 0;
  margin-bottom: 10px;
}

.product-price {
  font-size: 24px;
  font-weight: bold;
  color: $dark-color;
  margin: 10px 0;
}

.product-price del {
  font-size: 24px;
  font-weight: bold;
  color: $text-gray;
  margin: 10px 0;
}

.product-rating {
  /* Styles for Product Ratings and Reviews section */
  margin: 1rem 0;
}

.product-rating-star {
  font-size: 2rem;
  color: $main-color;
}

.product-variants {
  /* Styles for Product Variants Selection section */
}

.product-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.product-qa {
  /* Styles for Product Q&A Section */
}

.product-related {
  /* Styles for Related Products Section */
}

.product-social {
  /* Styles for Social Sharing Buttons */
}

.product-trust {
  /* Styles for Trust Signals section */
}

.product-quantity {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 1rem 0;
  border-bottom: 1px solid #eee;
  margin: 1rem 0;
  color: $text-gray;
  font-size: 14px;
  /* Styles for Quantity Selector section */
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 500px;
  width: 500px;
}

.image {
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #ccc;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #ccc;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
