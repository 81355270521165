.app-form {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
.app-form-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 99;
}
.app-form-content {
  position: absolute;
  z-index: 99;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 90px;
  overflow-y: auto;
}
.app-form-footer {
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  bottom: 0;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  padding: 1rem;
}

.btn-main-outline {
  background: $text-white;
  border: 2px solid $main-color;
  color: $main-color;
  font-weight: bold;
}
