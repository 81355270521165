.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

table {
  width: 100%;
}

table input[type="checkbox"] {
  margin: 0.5rem 0.5rem;
  height: 20px;
  width: 20px;
}

table img {
  height: 100px;
  width: 100px;
}

table .icon {
  font-size: 2rem;
  color: $main-color;
}

table .table-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

table .item {
  display: block;
  text-decoration: none;
  width: 600px;
  color: $dark-color;
}

table tr {
  vertical-align: text-top;
}
