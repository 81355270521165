$main-color: #dd2f6e;
$dark-color: #1d2231;
$text-gray: #8390a2;
$text-white: #ffffff;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
}

.app-content {
  margin-left: 345px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.btn-main {
  background-color: $main-color;
  color: $text-white;
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-outline {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-outline:hover {
  background: #eee;
}

.search-wrapper {
  border: 1px solid #ccc;
  border-radius: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  background: $text-white;
}

.search-wrapper span {
  display: inline-block;
  padding: 0 1rem;
  font-size: 1.5rem;
}

.search-wrapper input {
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
}

main {
  padding: 2rem 1.5rem;
  // background: #f1f5f9;
}

.app-sidebar-footer {
  margin-bottom: 1.3rem;
  text-align: center;
  color: $text-white;
}

@import "app.sidebar";
@import "app.form";
@import "app.flash";
@import "app.grid";
@import "app.product.detail";
@import "app.shop.detail";
