.flash-message {
  position: absolute;
  z-index: 9999;
  top: 1rem;
  right: 0;
  left: 0;
}
.flash-message-container {
  width: 500px;
  margin: auto;
}
